import { Button } from 'primereact/button';

type HeaderTableProps = {
  enabled: boolean;
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>;
}

export const HeaderTable = (props: HeaderTableProps) => {
  return (
    <div className="table-header flex justify-between">
      <h5 className="mx-0 my-1">Generaciones</h5>
      <div>
        <Button
          icon={props.enabled ? "pi pi-eye" : "pi pi-eye-slash"}
          className="p-button-sm"
          label={props.enabled ? "Ver todos" : "Ocultar deshabilitadas"}
          onClick={() => {
            console.log('Button clicked');
            props.setEnabled(!props.enabled);
          }}
          
        />
      </div>
    </div>
  );
}
