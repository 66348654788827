import { Button } from 'primereact/button';
import { useDialogCreateLinkHook } from '../context/TableContext';
type HeaderTableProps = {
  enabled: any;
  setEnabled: any;
}
export const HeaderTable = (props:HeaderTableProps) => {
  const { openDialog } = useDialogCreateLinkHook();

  return (
    <div className="table-header flex justify-between">
      <h5 className="mx-0 my-1">Tabla de precios</h5>
      <div>
        <Button
          icon={props.enabled ? "pi pi-eye" : "pi pi-eye-slash"}
          className="p-button-sm"
          label={props.enabled ? "Ver todas" : "Ocultar dehabilitadas"}
          onClick={() => {props.setEnabled(!props.enabled);}}
        />
        <Button
          icon="pi pi-plus"
          label="Nueva Tabla"
          className="p-button-success p-button-sm"
          onClick={openDialog}
        />
      </div>
    </div>
  );
};
