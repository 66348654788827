import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useEffect, useLayoutEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormDiscountType, FormEditDiscount } from '../../../../interfaces';
import { RequiredField } from '../../../../components';
import axios, { AxiosError } from 'axios';

type PropsFormDataEditDiscount = {
  actionToast: (action: string) => void;
  reload: () => void;
  id: number;
  close: () => void;
}
export const FormDataEditDiscount = ({
                                       actionToast,
                                       reload,
                                       id,
                                       close
                                     }: PropsFormDataEditDiscount) => {

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<FormEditDiscount | null>(null);
  const {
    handleSubmit,
    register,
    setValue,
    formState: { errors }
  } = useForm<FormDiscountType>({
    defaultValues: { ...data }
  });


  const fetchData = async () => {
    setData(null);
    setLoading(true);


    await axios
      .get(`${process.env.REACT_APP_API_BACKEND}/discounts/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
        }
      })
      .then((response) => {
        setData(response.data);
      })
      .catch((error: AxiosError) => console.log('Error =>', error))
      .finally(() => {
        setInterval(() => setLoading(false), 1000);
      });
  };

  useLayoutEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (data) {
      setValue('description', data.description);
      setValue('percentage', data.percentage);
      setValue('enabled', data.enabled);
      setValue('sis_discount_motive_id', data.sis_discount_motive_id);
    }
  }, [data]);
  const onSubmit: SubmitHandler<FormDiscountType> = (data) => {
    axios.patch(`${process.env.REACT_APP_API_BACKEND}/discounts/${id}`, data, {
      data,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
      }
    }).then((response) => {
      console.log('Response Discount =>', response.data);
      actionToast('edit');
      reload();
    })
      .catch((error: AxiosError) => {
        console.log('Error Discount =>', error);
      })
      .finally(() => {
        close();
      });
  };

  return <div className='w-full'>
    {loading ? (
      <div className='absolute top-0 left-0 w-full bg-white z-10 grid place-items-center rounded-xl'>
        <div className='flex flex-col items-center'>
          <ProgressSpinner />
          <h1>Cargando..</h1>
        </div>
      </div>
    ) : (<>
      <form onSubmit={handleSubmit(onSubmit)} className='relative'>
        <hr className='py-3' />


        <div className='grid grid-rows-1 grid-flow-col gap-4'>
          <div className='flex flex-col'>
            <label>Descripción</label>
            <input
              type='text'
              {...register('description', {
                required: true
              })}
              className={
                errors.description
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {errors.description && <RequiredField />}
          </div>
          <div className='flex flex-col'>
            <label>Porcentage</label>
            <input
              type='float'
              {...register('percentage', {
                required: true,
                valueAsNumber: true,
                min: 0,
                max: 100
              })}
              className={
                errors.percentage
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {errors.percentage && <RequiredField />}
          </div>
          <div className='flex flex-col'>
            <label>Id Motivo descuento Sis</label>
            <input
              type='number'
              {...register('sis_discount_motive_id', {
                required: true,
                valueAsNumber: true
              })}
              className={
                errors.sis_discount_motive_id
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {errors.percentage && <RequiredField />}
          </div>
        </div>

        <div className={'flex justify-end mt-5'}>
          <button
            className='m-1 px-5 rounded-lg text-white bg-gray-500'
            style={{ border: '3px solid gray' }}
            //onClick={null}
          >
            Cancelar
          </button>
          <button
            type='submit'
            className='m-1 px-5 rounded-lg text-white bg-green-500'
            style={{ border: '3px solid rgb(34 197 94)' }}
          >
            Guardar
          </button>
        </div>
      </form>
    </>)}
  </div>;
};