import { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { ProgressSpinner } from 'primereact/progressspinner';
import '../styles.css';
import { GetCareers } from '../../../../services';
import { Career, CareerPrice, RegisterLinkType } from '../../../../interfaces';
import axios, { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';

type DialogCreateLinkTypes = {
  open: boolean;
  close: () => void;
  actionToast: (action: string) => void;
  addData: (data: any) => void;
};

export const DialogCreateLink = (props: DialogCreateLinkTypes) => {
  const modalRef = useRef<HTMLDivElement>(null!);
  const containerRef = useRef<HTMLDivElement>(null!);
  const [inputEmail, setInputEmail] = useState('');
  const [selectedCareers, setSelectedCareers] = useState<Career>(null!);
  const [selectForm, setSelectForm] = useState<any>(null!);
  const [nameUser, setNameUser] = useState<string | number>(null!);
  const { careers } = GetCareers();
  const [disabledInput, setdisabledInput] = useState(true);
  const [errorEmail, setErrorEmail] = useState(false);
  const { handleSubmit, setValue } = useForm<RegisterLinkType>();
  const [loading, setLoading] = useState(false);
  const [disabledForm, setDisabledForm] = useState(true);
  const [checkRadioBtn, setCheckRadioBtn] = useState(false);
  const [generations, setGenerations] = useState<any>(null!);
  const [discounts, setDiscounts] = useState<any>(null!);
  const [selectedGeneration, setSelectedGeneration] = useState<any>(null!);
  const [selectedDiscount, setSelectedDiscount] = useState<any>(null);
  const [tuitionExempted, setTuitionExempted] = useState<any>(false);
  const [isVariant, setIsVariant] = useState<any>(false);


  const forms = [
    // { name: 'Formulario de carrera', id: 1 },
    { name: 'Formulario de cursos', id: 2 },
    // { name: 'Formulario de reintegro', id: 3 },
    // { name: 'Formulario de talleres', id: 4 },
    { name: 'Formulario de carrera simplificado', id: 5 }
  ];

  useEffect(() => {
    if (props.open) modalRef.current.style.display = 'flex';
  }, [props.open]);

  const closeModal = (): void => {
    setLoading(false);
    setDisabledForm(true);
    containerRef.current.classList.add('close');
    setTimeout(() => {
      containerRef.current.classList.remove('close');
      modalRef.current.style.display = 'none';
      props.close();
    }, 1000);
  };

  window.addEventListener(
    'click',
    (e: any) => e.target === modalRef.current && closeModal()
  );

  const searchUserByEmail = async () => {
    const email = inputEmail;
    setValue('email', email);

    await axios
      .get(
        `${process.env.REACT_APP_API_BACKEND}/hubspot/client/${inputEmail}/email`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
          }
        }
      )
      .then((response: any) => {
        // console.log('Response Search by Email =>!', response.data);

        setNameUser(
          `${response.data.results[0].properties.firstname} ${response.data.results[0].properties.lastname}`
        );

        setValue('name', response.data.results[0].properties.firstname);
        setValue('lastname', response.data.results[0].properties.lastname);
        setValue('lastname2', '');

        // console.log('Response Hubspot by Email =>', response);
        setdisabledInput(false);
        setErrorEmail(false);
      })
      .catch((error: AxiosError) => {
        console.log('Error ingress Email =>', error);
        setErrorEmail(true);
      });
  };

  const handleChangeCareer = async (value: Career) => {
    setSelectedCareers(value);
    setValue('career_id', value.id);
    if (value.prices.length) setValue('price_id', value.prices[0].id);
    getGenerations(value.id);
    getDiscounts();
  };

  const handleChangeGeneration = (value: any) => {
    setSelectedGeneration(value);
    setValue('generation_id', value.id);
    setCheckRadioBtn(false);
  };
  console.log('selectedDiscount', selectedDiscount);
  const handleChangeDiscount = (value: any) => {
    setSelectedDiscount(value);
    setValue("discount_id", value ? value.id : null); // Set to null if nothing is selected
  };


  const getDiscounts = async () => {
    await axios.get(`${process.env.REACT_APP_API_BACKEND}/discounts?enabled=1`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
        }
      }
    ).then((response: any) => {
      setDiscounts(response.data);
      //setSelectedDiscount(response.data[0]);
      setValue('discount_id', null);

    }).catch((error: AxiosError) => {
      console.log('Error', error);
    });
  }
  const getGenerations = async (career_id: number) => {
    await axios.get(`${process.env.REACT_APP_API_BACKEND}/careers/${career_id}/generations`,
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
        }
      }
    ).then((response: any) => {
      setGenerations(response.data);
      setSelectedGeneration(response.data[0]);
      setValue('generation_id', response.data[0].id);
    }).catch((error: AxiosError) => {
      console.log('Error', error);
    });
  };

  const formatPrice = (value: number) => {
    return new Intl.NumberFormat('es-ES', {}).format(value);
  };

  const handleChangeFormType = (value: { id: number; name: string }) => {
    setSelectForm(value);
    setValue('form_type_id', value.id);
    if (checkRadioBtn) setDisabledForm(false);
  };

  const handleChangeRadio = (row: CareerPrice) => {
    // console.log('Radio Selected => ', row);
    setValue('price_id', row.id);
    setCheckRadioBtn(true);
    if (selectForm) setDisabledForm(false);
  };

  const onSubmit: SubmitHandler<RegisterLinkType> = async (data) => {
    setLoading(true);

    await axios
      .post(`${process.env.REACT_APP_API_BACKEND}/registers`, data, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
        }
      })
      .then(async (response: any) => {
        console.log('response onSubmit =>', response);
        props.addData(response.data);
        await axios
          .get(
            `${process.env.REACT_APP_API_BACKEND}/registers/${response.data[0].id}/notification`,
            {
              headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
              }
            }
          )
          .then(() => {
            props.actionToast('success');
          })
          .catch((error: AxiosError) => console.log('Error => ', error))
          .finally(() => closeModal());
      })
      .catch((error: AxiosError) => console.log('Error onSubmit =>', error));
  };

  return (
    <div className='window-background' id='window-background' ref={modalRef}>
      <div
        className='window-container'
        id='window-container'
        ref={containerRef}
      >
        {loading ? (
          <div className='absolute top-0 left-0 w-full h-full bg-white z-10 grid place-items-center rounded-xl'>
            <div className='flex flex-col items-center'>
              <ProgressSpinner />
              <h1>Cargando..</h1>
            </div>
          </div>
        ) : (
          <>
            <p className='text-2xl'>Crear Enlace</p>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className='grid grid-cols-2 gap-4 mt-5'>
                <div>
                  <p className='font-thin'>Usuario</p>
                  <div>
                    <input
                      type='email'
                      value={inputEmail}
                      onKeyDown={(e: any) => {
                        if (e.code === 'Enter') {
                          e.preventDefault();
                        }
                      }}
                      placeholder='Correo electrónico'
                      onChange={(e) => setInputEmail(e.target.value)}
                      className='border-r-0 py-2 rounded-none rounded-l-lg w-8/12'
                      style={{ border: '1px solid gray' }}
                    />
                    <button
                      type='button'
                      className='bg-green-500 py-[9px] px-5 rounded-tr-lg rounded-br-lg text-white'
                      onClick={searchUserByEmail}
                    >
                      Buscar
                    </button>
                  </div>
                  {errorEmail && (
                    <span className='text-red-500 text-sm font-sm'>
                      Usuario no encontrado
                    </span>
                  )}
                </div>
                <div>
                  <p className='font-thin'>Nombre de usuario</p>
                  <div>
                    <input
                      type='text'
                      value={nameUser}
                      className='border-r-0 py-2 rounded-lg w-10/12 bg-gray-300'
                      style={{ border: '1px solid gray' }}
                      disabled
                    />
                  </div>
                </div>
                <div className='mt-5'>
                  <p className='font-thin'>Cursos</p>
                  <div>
                    <Dropdown
                      value={selectedCareers}
                      options={careers}
                      disabled={disabledInput}
                      onChange={(e) => handleChangeCareer(e.value)}
                      optionLabel='description'
                      filter
                      placeholder='Seleccionar Curso'
                      className='w-full md:w-14rem'
                    />
                  </div>
                </div>
                <div className='mt-5'>
                  <p className='font-thin'>Generaciones</p>
                  <div>
                    <Dropdown
                      value={selectedGeneration}
                      options={generations}
                      disabled={disabledInput}
                      onChange={(e) => handleChangeGeneration(e.value)}
                      optionLabel='description'
                      filter
                      placeholder='Seleccionar Generación'
                      className='w-full md:w-14rem'
                    />
                  </div>
                </div>
                <div className='mt-5'>
                  <p className='font-thin'>Tipo de Formulario</p>
                  <div>
                    <Dropdown
                      value={selectForm}
                      options={forms}
                      disabled={disabledInput}
                      onChange={(e) => handleChangeFormType(e.value)}
                      optionLabel='name'
                      placeholder='Seleccionar Formulario'
                      className='w-full md:w-14rem'
                      required
                    />
                  </div>
                </div>
                <div className='mt-5'>
                  <p className='font-thin'>Descuentos</p>
                  <div>
                    <Dropdown
                      value={selectedDiscount}
                      options={discounts}
                      disabled={disabledInput}
                      onChange={(e) => handleChangeDiscount(e.value)}
                      optionLabel='description'
                      filter
                      placeholder='Seleccionar Descuento'
                      className='w-full md:w-14rem'
                    />
                  </div>
                </div>
                <div className='mt-5'>
                  <p className='front-thin'>Es variante {selectedCareers && `(${selectedCareers.variant_friendly_name})`} </p>
                  <input
                    type="checkbox"
                    value={isVariant}
                    disabled={!selectedCareers || !selectedCareers.has_variant}
                    onChange={(event) => {
                      setIsVariant(event.target.checked);
                      setValue("variant_application", event.target.checked);
                    }}
                  />{' '}
                  <span className="text">Matricular en variante</span>
                </div>
                <div className='mt-5'>
                  <p className='font-thin'>Exención de matrícula</p>
                  <input
                    type="checkbox"
                    value={tuitionExempted}
                    onChange={(event) => {
                      setTuitionExempted(event.target.checked);
                      setValue("tuition_exempted", event.target.checked);
                    }}
                  />{' '}
                  <span className="text">No paga matrícula</span>
                </div>
              </div>
              {selectedCareers && (
                <div className='mt-5 col-span-12 max-h-[200px] overflow-scroll'>
                  <p className='font-thin'>Tabla de precios</p>
                  <div>
                    <table className='w-full text-sm text-left text-gray-500 dark:text-gray-400'>
                      <thead className='text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400'>
                      <tr>
                        <th></th>
                        <th scope='col' className='px-6 py-3'>
                          Nombre
                        </th>
                        <th scope='col' className='px-6 py-3'>
                          Matricula
                        </th>
                        <th scope='col' className='px-6 py-3'>
                          Comentarios
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {selectedCareers.prices.map((price, index) => {
                        return (
                          <tr key={index}>
                            <td>
                              <input
                                type='radio'
                                name='select-row'
                                onChange={() => handleChangeRadio(price)}
                              />
                            </td>
                            <td>{price.name}</td>
                            <td>$ {formatPrice(price.tuition)}</td>
                            <td>{price.comments}</td>
                          </tr>
                        );
                      })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              <div className='mt-5  w-full flex justify-end'>
                <button
                  type='button'
                  className='m-1 px-5 rounded-lg text-white bg-gray-500'
                  style={{ border: '3px solid gray' }}
                  onClick={() => closeModal()}
                >
                  Cancelar
                </button>
                <button
                  type='submit'
                  className={`m-1 px-5 rounded-lg text-white ${
                    disabledForm ? 'bg-gray-500' : ' bg-green-500'
                  }`}
                  style={{
                    border: disabledForm
                      ? '3px solid gray'
                      : '3px solid rgb(34 197 94)'
                  }}
                  disabled={disabledForm}
                >
                  Crear
                </button>
              </div>
            </form>
          </>
        )}
      </div>
    </div>
  );
};
