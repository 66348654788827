import { useDialogCreateDiscountHook } from '../context/TableContext';
import { Dialog } from 'primereact/dialog';
import { DiscountFormProvider, initialValue } from '../context/DiscountFormContext';
import { useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import { FormDataDiscount } from './FormDataDiscount';


type PropsDialogDiscount = {
  actionToast: (action: string) => void;
  reload: () => void;
}
export const DialogTableDiscount = ({
                                      actionToast,
                                      reload
                                    }: PropsDialogDiscount) => {
  const { isOpenDialog, closeDialog } = useDialogCreateDiscountHook();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Dialog
        visible={isOpenDialog}
        header='Crear nuevo descuento'
        draggable={false}
        resizable={false}
        modal
        className='p-fluid w-9/12 relative'
        onHide={closeDialog}>
        <DiscountFormProvider {...initialValue}>
          <>
            {loading && (
              <div className='absolute top-0 left-0 w-full h-full bg-white z-10 grid place-items-center rounded-xl'>
                <div className='flex flex-col items-center'>
                  <ProgressSpinner />
                  <h1>Cargando..</h1>
                </div>
              </div>
            )}
            <FormDataDiscount
              actionToast={actionToast}
              isLoad={setLoading}
              reload={reload}
            />
          </>
        </DiscountFormProvider>
      </Dialog>
    </>);
};
