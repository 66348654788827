import { DataTable } from 'primereact/datatable';
import { HeaderTable } from './HeaderTable';
import { useEffect, useState } from 'react';
import { getCareersWithGenerations } from '../../../../services';
import { NotificationComponent } from '../../../../components/NotificationComponent';
import { FilterMatchMode } from 'primereact/api';
import { Column } from 'primereact/column';
import { Toast } from 'primereact/toast';
import { Button } from 'primereact/button';
import axios from 'axios';
import { DialogEditGeneration } from './DialogEditGeneration';
import { Generation } from '../../../../interfaces/Generation';
import { Tooltip } from 'primereact/tooltip';

// Define the type of each item in the array

export const TableGenerations = () => {
  const { toast, showSuccess, showSuccessEdit } = NotificationComponent();
  const [careersWithGenerations, setCareersWithGenerations] = useState<Generation[]>([]);
  const [rowSelected, setRowSelected] = useState<any>(null!);
  const [loading, setLoading] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [openDialogEditGeneration, setOpenDialogEditGeneration] = useState(false);


  useEffect(() => {
    console.log('Enabled state:', enabled);
  }, [enabled]);

  useEffect(() => {
    reload();
  }, [enabled]);

  const reload = async () => {
    setLoading(true);
    try {
      const data = await getCareersWithGenerations();
      let filteredData = data;

      if (enabled) {
        filteredData = data.filter((item: Generation) => item.enabled);
      }

      setCareersWithGenerations(filteredData);
    } catch (error) {
      console.error('Error fetching careers with generations:', error);
    } finally {
      setLoading(false);
    }
  };


  const filters = {
    description: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    },
    sis_generation_id: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    },
    sis_segment_code: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    },
    enabled: {
      value: '',
      matchMode: FilterMatchMode.CONTAINS
    }
  };

  const toggleCommand = (generationId: number | null) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/generations/${generationId}/toggle`,
        {},
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
          }
        }
      )
      .then(() => {
        reload();
      })
      .catch(error => {
        console.error('Error toggling generation:', error);
      });
  };

  const actionToast = (action: string) => {
    switch (action) {
      case 'success':
        showSuccess();
        break;
      case 'edit':
        showSuccessEdit();
        break;

      default:
        break;
    }
  };

  const actionEdit = (rowData: any) => {
    return (
      <Button
        icon='pi pi-pencil'
        className='p-button-rounded p-button-text p-button-warning '
        data-te-toggle='tooltip'
        title='Editar'
        onClick={() => {
          setRowSelected(rowData);
          setOpenDialogEditGeneration(true);
        }}
      />
    );
  }

  const togglePublished = (generationId: number | null) => {
    axios
      .post(
        `${process.env.REACT_APP_API_BACKEND}/generations/${generationId}/togglePublished`,
        {},
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
          }
        }
      )
      .then(() => {
        reload();
      })
      .catch(error => {
        console.error('Error toggling generation:', error);
      });
  };

  const actionToggle = (rowData: Generation) => {
    return (
      <Button
        icon={rowData.enabled ? 'pi pi-eye' : 'pi pi-eye-slash'}
        className={`p-button-rounded p-button-text ${rowData.enabled ? '' : 'p-button-danger'}`}
        data-te-toggle='tooltip'
        title={rowData.enabled ? 'Ocultar' : 'Mostrar'}
        tooltip={rowData.enabled ? 'Ocultar' : 'Mostrar'}
        onClick={() => toggleCommand(rowData.id)}
      />
    );
  };
  const publishToggle = (rowData: Generation) => {
    console.log(rowData.published);
    return (
      <Button
        icon={rowData.published ? 'pi pi-download': 'pi pi-upload' }
        className={`p-button-rounded p-button-text ${rowData.published ? 'p-button-danger' : ''}`}
        data-te-toggle='tooltip'
        title={rowData.published ? 'Dar de baja' : 'Publicar'}
        tooltip={rowData.published ? 'Dar de baja': 'Publicar'}
        onClick={() => togglePublished(rowData.id)}
      />
    );
  };

  const tooltipAlerts = (rowData: Generation) => {

    let warnings = []
    if(rowData.published && !rowData.default_price_table_id){
      warnings.push('Publicada sin tabla de precios asignada');
    }
    return (<div>
      <Tooltip target=".custom-target-icon" />
      <i className="custom-target-icon pi pi-exclamation-triangle p-overlay-badge"
         data-pr-tooltip={warnings.join(', ')}
         data-pr-position="right"
         data-pr-at="right+5 top"
         data-pr-my="left center-2"
         style={{ fontSize: '2rem', cursor: 'pointer' , color: warnings.length === 0 ? 'lightblue': 'red'}}>

      </i>
    </div>);
  }


  console.log('carrers', careersWithGenerations);
  return (
    <>
      <Toast ref={toast} />
      <DataTable
        value={careersWithGenerations}
        responsiveLayout='stack'
        breakpoint='960px'
        dataKey='id'
        emptyMessage='No se encontraron datos'
        rows={15}
        filters={filters}
        loading={loading}
        filterDisplay='row'
        paginator
        className='shadow-lg shadow-gray-500/30'
        header={<HeaderTable enabled={enabled} setEnabled={setEnabled} />}
      >
        <Column field='id' header='Id' sortable />
        <Column
          field='description'
          filter
          showFilterMenu={false}
          filterPlaceholder='Buscar por generación'
          header='Tabla de generaciones'
          sortable
        />
        <Column
          field='sis_generation_id'
          filter
          showFilterMenu={false}
          filterPlaceholder='Buscar por sis id'
          header='Id de Sis'
          sortable
        />
        <Column
          field='sis_segment_code'
          header='Código de Segmento'
          showFilterMenu={false}
          filter
          filterPlaceholder='Buscar por segmento'
          sortable
        />
        <Column
          body={actionEdit}
          exportable={false}
          style={{ minWidth: '8rem' }}>
        </Column>
        <Column
          body={actionToggle}
          exportable={false}
          style={{ minWidth: '8rem' }}
        />
        <Column
          body={publishToggle}
          exportable={false}
          style={{ minWidth: '8rem' }}
        />
        <Column
          body={tooltipAlerts}
          exportable={false}
          style={{ minWidth: '8rem' }}
        />
      </DataTable>
      {openDialogEditGeneration && (
        <DialogEditGeneration
          actionToast={actionToast}
          id={rowSelected.id}
          reload={reload}
          open={openDialogEditGeneration}
          close={() => setOpenDialogEditGeneration(false)} />
      )}
    </>
  );
};
