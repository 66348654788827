import { useContext } from 'react';
import { TableContext } from '../../section-payment/context/TableContext';

export const useDialogCreateDiscountHook = (): UseDialogCreateDiscountType => {
  const {
    state: { isOpenDialog },
    openDialog,
    closeDialog
  } = useContext(TableContext);

  return { isOpenDialog, closeDialog, openDialog };
};

type UseDialogCreateDiscountType = {
  isOpenDialog: boolean;
  openDialog: () => void;
  closeDialog: () => void;
};