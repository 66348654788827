import { ProgressSpinner } from 'primereact/progressspinner';
import React, { useLayoutEffect, useState } from 'react';
import { Currency, DefaultPrice } from '../../../../interfaces';
import { FormEditGeneration, Generation } from '../../../../interfaces/Generation';
import axios, { AxiosError } from 'axios';
import { SubmitHandler, useForm } from 'react-hook-form';
import { CurrenciesPricesTableSelector } from './CurrenciesPricesTableSelector';

type PropsFormDataEditGeneration = {
  actionToast: (action: string) => void;
  reload: () => void;
  id: number;
  close: () => void;
}

export const FormDataEditGeneration = ({
                                         actionToast,
                                         reload,
                                         id,
                                         close
                                       }: PropsFormDataEditGeneration) => {

  const [loading, setLoading] = useState(false);
  const {
    handleSubmit,
    register,
    setValue
  } = useForm<FormEditGeneration>({});
  const [data, setData] = useState<Generation | null>(null);
  const [currencies, setCurrencies] = useState<Currency[]>([]);
  const [prices, setPrices] = useState([]);
  const [defaultPrices, setDefaultPrices] = useState<DefaultPrice[]>([]);
  const fetchData = async () => {

    setData(null);
    setLoading(true);

    await axios
      .get(`${process.env.REACT_APP_API_BACKEND}/currencies`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
        }
      })
      .then((response) => {
        console.log(response.data);
        setCurrencies(response.data);
      })
      .catch((error: AxiosError) => {
        console.log(error.message);
        }
        );

    await axios
      .get(`${process.env.REACT_APP_API_BACKEND}/generations/${id}`, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
        }
      })
      .then((response) => {
        setData(response.data);
        setDefaultPrices(response.data.default_prices);
      })
      .catch((error: AxiosError) => console.log('Error =>', error))
      .finally(() => {
        setInterval(() => setLoading(false), 1000);
      });
  };

  const setEditableData = () => {
    if (data) {
      setValue('enabled', data?.enabled);
      setValue('published', data.enabled);
      setValue('default_price_table_id', data.default_price_table_id);
    }

  };
  const fetchCareer = async () => {
    setLoading(true);
    if (data?.career_id) {
      await axios
        .get(`${process.env.REACT_APP_API_BACKEND}/careers/${data.career_id}`, {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
          }
        })
        .then((response) => {
          setPrices(response.data.prices);
        })
        .catch((error: AxiosError) => console.log('Error =>', error))
        .finally(() => {
          setInterval(() => setLoading(false), 1000);
        });
    }
  };

  useLayoutEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useLayoutEffect(() => {
    fetchCareer();
    setEditableData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit: SubmitHandler<FormEditGeneration> = (data) => {

    data.default_prices = defaultPrices;
    axios.patch(`${process.env.REACT_APP_API_BACKEND}/generations/${id}`, data, {
      data,
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
      }
    }).then((response) => {
      console.log('Response Discount =>', response.data);
      actionToast('edit');
      reload();
    }).catch((error: AxiosError) => {
      console.log('Error Discount =>', error);
    })
      .finally(() => {
        close();
      });
  }

  return <div className='w-full'>
    {loading ? (
        <div className='absolute top-0 left-0 w-full bg-white z-10 grid place-items-center rounded-xl'>
          <div className='flex flex-col items-center'>
            <ProgressSpinner />
            <h1>Cargando..</h1>
          </div>
        </div>
      )
      :
      (<>
        <form onSubmit={handleSubmit(onSubmit)} className='relative'>
          <hr className='py-3' />
          <div className='grid grid-rows-1 grid-flow-col gap-4'>
            <div className='flex flex-col'>
              <label>Descripción</label>
              <input
                type='text'
                value={data?.description || ''}
                className='py-1 rounded-lg'
                disabled
              />
            </div>
            <div className='flex flex-col'>
              <label>Codigo de segmento</label>
              <input
                type='text'
                value={data?.sis_segment_code || ''}
                className='py-1 rounded-lg'
                disabled
              />
            </div>
            <div className='flex flex-col'>
              <label>Habilitada</label>
              <input
                type='checkbox'
                value='Enabled'
                {...register('enabled')}
                className='py-1 rounded-lg'
                readOnly
              />
            </div>
            <div className='flex flex-col'>
              <label>Publicada</label>
              <input
                type='checkbox'
                value='Publicada'
                {...register('published')}
                className='py-1 rounded-lg'
                readOnly
              />
            </div>
          </div>
          <div className='grid grid-rows-1 grid-flow-col gap-4'>
            <CurrenciesPricesTableSelector prices={prices} currencies={currencies} defaultPrices={defaultPrices} setDefaultPrices={setDefaultPrices} generation_id={id}/>
          </div>
          <div className={'flex justify-end mt-5'}>
            <button
              className='m-1 px-5 rounded-lg text-white bg-gray-500'
              style={{ border: '3px solid gray' }}
              //onClick={null}
            >
              Cancelar
            </button>
            <button
              type='submit'
              className='m-1 px-5 rounded-lg text-white bg-green-500'
              style={{ border: '3px solid rgb(34 197 94)' }}
            >
              Guardar
            </button>
          </div>
        </form>
      </>)}
  </div>;
};