import React, { useState } from 'react';
import { useDialogCreateLinkHook } from '../context/TableContext';
import { useForm, SubmitHandler } from 'react-hook-form';
import { initialValue } from '../context/PaymentFormContext';
import {
  Career,
  Currency,
  FormPaymentType,
  PaymentMethod,
  SupplierId
} from '../../../../interfaces';
import { Dropdown, DropdownChangeParams, DropdownItemTemplateType } from 'primereact/dropdown';
import { GetCareers, GetCurrencies } from '../../../../services';
import '../payment-styles.css';
import axios, { AxiosError } from 'axios';

type PropsFormDataProgram = {
  actionToast: (action: string) => void;
  isLoad: (value: boolean) => void;
  addData: (data: any) => void;
};

export const FormDataProgram = ({
                                  actionToast,
                                  isLoad,
                                  addData
                                }: PropsFormDataProgram) => {
  const { closeDialog } = useDialogCreateLinkHook();
  const { currencies } = GetCurrencies();
  const [selectedCareers, setSelectedCareers] = useState<Career>(null!);
  const { careers } = GetCareers();
  const [cashType, setCashType] = useState<Currency>({ id: 0, description: '', code: '' });
  //Estados para metodos de pago
  const [checkPaymentQuotes, setcheckPaymentQuotes] = useState(false);
  const [checkPrepaid, setCheckPrepaid] = useState(false);
  const [checkISA, setCheckISA] = useState(false);
  const [valueQuotes, setValueQuotes] = useState(0);
  const [variantValueQuotes, setVariantValueQuotes] = useState(0);
  const [checkPaypal, setCheckPaypal] = useState(false);
  const [checkFlow, setCheckFlow] = useState(false);
  const [checkTransbank, setCheckTransbank] = useState(false);
  const [checkEBanx, setCheckEBanx] = useState(false);
  const [checkOtherMethods, setCheckOtherMethods] = useState(false);
  const [hasVariant, setHasVariant] = useState(false);
  const {
    handleSubmit,
    register,
    watch,
    getValues,
    setValue,
    formState: { errors }
  } = useForm<FormPaymentType>({
    defaultValues: { ...initialValue }
  });

  const onSubmit: SubmitHandler<FormPaymentType> = (data) => {
    data.currency_id = cashType.id;
    data.career_id = Number(selectedCareers.id);

    let suppliers: SupplierId[] = [];

    const clearArr: PaymentMethod[] = [];

    if (data.payment_methods[0].reference_value && checkPaymentQuotes)
      clearArr.push({
        payment_method_id: 1,
        quotes: data.payment_methods[0].quotes,
        reference_value: data.payment_methods[0].reference_value,
        variant_reference_value: data.payment_methods[0].variant_reference_value,
        quotes_value: data.payment_methods[0].quotes_value,
        variant_quotes_value: data.payment_methods[0].variant_quotes_value,
        advance_discount: null,
        free_discount: data.payment_methods[0].free_discount,
        isa_percent: null,
        isa_value: null,
        variant_isa_value: null
      });
    if (data.payment_methods[1].reference_value && checkPrepaid)
      clearArr.push({
        payment_method_id: 2,
        quotes: null,
        reference_value: data.payment_methods[1].reference_value,
        variant_reference_value: data.payment_methods[1].variant_reference_value,
        quotes_value: null,
        variant_quotes_value: null,
        advance_discount: data.payment_methods[1].advance_discount,
        free_discount: null,
        isa_percent: null,
        isa_value: null,
        variant_isa_value: null
      });
      if (data.payment_methods[2].isa_value && checkISA)
      clearArr.push({
        payment_method_id: 3,
        quotes: null,
        reference_value: null,
        variant_reference_value: null,
        quotes_value: null,
        variant_quotes_value: null,
        advance_discount: null,
        free_discount: null,
        isa_percent: data.payment_methods[2].isa_percent,
        isa_value: data.payment_methods[2].isa_value,
        variant_isa_value: data.payment_methods[2].variant_isa_value
      });

    data.payment_methods = clearArr;

    // suppliers = getValues('suppliers').map((v: any) => {
    //   return { supplier_id: Number(v) };
    // });

    if (checkFlow) {
      suppliers.push({ supplier_id: 1 });
    }
    if (checkPaypal) {
      suppliers.push({ supplier_id: 2 });
    }
    if (checkTransbank) {
      suppliers.push({ supplier_id: 3 });
    }
    if (checkOtherMethods) {
      suppliers.push({ supplier_id: 4 });
    }
    console.log(suppliers);

    data.suppliers = suppliers;

    isLoad(true);

    axios
      .post(`${process.env.REACT_APP_API_BACKEND}/prices`, data, {
        headers: {
          'Access-Control-Allow-Origin': '*',
          Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
        }
      })
      .then((response) => {
        console.log('Response Price =>', response.data);
        actionToast('success');
        addData(response.data);
      })
      .catch((error: AxiosError) => {
        console.log('Error Price =>', error);
      })
      .finally(() => {
        isLoad(false);
        closeDialog();
      });
  };

  const careerItemTemplate = (option: any) => {
    return <div className='ui-helper-clearfix'>
      {option.friendly_name + (option.has_variant ? '/' + option.variant_friendly_name : '')}
    </div>;
  };
  const RequiredField = () => {
    return <span className='text-red-500 text-[12px]'>Campo requerido</span>;
  };


  const calculateValueQuotes = () => {

    let value = 0;
    let variantValue = 0;
    if (
      watch('payment_methods.0.reference_value') &&
      watch('payment_methods.0.quotes')
    ) {
      value = Math.round(
        Number(getValues(`payment_methods.0.reference_value`)) /
        Number(getValues(`payment_methods.0.quotes`))
      );
    }

    if (
      watch('payment_methods.0.variant_reference_value') &&
      watch('payment_methods.0.quotes')
    ) {
      variantValue = Math.round(
        Number(getValues(`payment_methods.0.variant_reference_value`)) /
        Number(getValues(`payment_methods.0.quotes`))
      );
    }

    if (
      watch('payment_methods.0.quotes') === 0 ||
      watch('payment_methods.0.quotes') === undefined
    ) {
      value = Number(watch('payment_methods.0.reference_value'));
      variantValue = Number(watch('payment_methods.0.variant_reference_value'));
    }

    setValueQuotes(value);
    setVariantValueQuotes(variantValue)
    setValue('payment_methods.0.quotes_value', value);
    setValue('payment_methods.0.variant_quotes_value', variantValue);
  };


  const checkHandler = (id: string) => {
    if (id.toString() !== '4') {
      setCheckPaypal(false);
    }
    if (id.toString() !== '1') {
      setCheckFlow(false);
    }
  };

  const setChangeCheckPaypal = (check: boolean) => {
    setCheckPaypal(!checkPaypal);
    if ((check === true && cashType.id === 4) || (check === true && cashType.id === 5)) {
      setCheckPaypal(true);
    } else {
      setCheckPaypal(false);
    }
  };

  const setChangeCheckFlow = (check: boolean) => {
    setCheckFlow(!checkFlow);
    if (check === true && cashType.id === 1) {
      setCheckFlow(true);
    } else {
      setCheckFlow(false);
    }
  };

  return (
    <div className='w-full'>
      <form onSubmit={handleSubmit(onSubmit)} className='relative'>
        <p className='py-3 font-bold'>Datos Principales</p>
        <hr className='py-3' />
        {/* Datos Principales */}
        <div className='grid grid-rows-1 grid-flow-col gap-4'>
          <div className='flex flex-col'>
            <label>Nombre</label>
            <input
              type='text'
              {...register('name', { required: true })}
              className={
                errors.name
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {errors.name && <RequiredField />}
          </div>
          <div className='flex flex-col'>
            <label>Tipo de moneda</label>
            <Dropdown
              value={cashType}
              options={currencies}
              emptyMessage={'Sin resultado'}
              optionLabel='code'
              placeholder='Seleccionar Moneda'
              className='w-full dropdown-form md:w-14rem'
              {...register('currency_id', {
                required: true,
                onChange: (evt: DropdownChangeParams) => {
                  if (evt.value.id) {
                    setCashType(evt.value);
                    checkHandler(evt.value.id);
                  }
                  ;
                }
              })}
            />
            {errors.currency_id && <RequiredField />}
          </div>
          <div className={'flex flex-col'}>
            <label>Matrícula</label>
            <input
              type='text'
              onKeyDown={(input: any) => {
                const esNumero =
                  (input.keyCode >= 48 && input.keyCode <= 57) || // números de teclado normal
                  (input.keyCode >= 96 && input.keyCode <= 105) ||
                  input.keyCode === 75 ||
                  input.keyCode === 8; // números del teclado numérico

                if (!esNumero) {
                  input.preventDefault(); // detiene la propagación del evento
                }
              }}
              {...register('tuition', {
                required: true,
                valueAsNumber: true,
                min: 0,
                max: 999999999
              })}
              className={
                errors.tuition
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {errors.tuition?.type === 'required' && <RequiredField />}
            {errors.tuition?.type === 'min' ||
              (errors.tuition?.type === 'max' && (
                <span className='text-red-500 text-[12px]'>
                  El valor estar entre 1 y 999999999
                </span>
              ))}
          </div>
          <div className='flex flex-col'>
            <label>Programa</label>
            <Dropdown
              value={selectedCareers}
              options={careers}
              optionLabel='friendly_name'
              filter
              itemTemplate={careerItemTemplate}
              placeholder='Seleccionar Curso'
              className='w-full dropdown-form md:w-14rem'
              {...register('career_id', {
                required: true,
                onChange: (evt: DropdownChangeParams) => {
                  if (evt.value && evt.value.id) {
                    setSelectedCareers(evt.value);
                    setHasVariant(evt.value.has_variant);
                  }
                }
              })}
            />
            {errors.career_id && <RequiredField />}
          </div>
        </div>
        {/* Formas de Pago */}
        <p className='font-bold text-xl py-3'>Formas de pago</p>
        <hr className='py-3' />
        <label className='mx-2'>
          <input
            type='checkbox'
            value={'Pago Cuota'}
            onChange={() => setcheckPaymentQuotes(!checkPaymentQuotes)}
          />{' '}
          <span className='font-bold text-lg'>Pago en cuotas</span>
        </label>
        <div className={'grid grid-rows-1 grid-flow-col gap-4'}>
          <div className={'flex flex-col'}>
            <label>Valor de referencia</label>
            <input
              type='text'
              disabled={!checkPaymentQuotes}
              onKeyDown={(input: any) => {
                const esNumero =
                  (input.keyCode >= 48 && input.keyCode <= 57) || // números de teclado normal
                  (input.keyCode >= 96 && input.keyCode <= 105) ||
                  input.keyCode === 75 ||
                  input.keyCode === 8; // números del teclado numérico

                if (!esNumero) {
                  input.preventDefault(); // detiene la propagación del evento
                }
              }}
              {...register(`payment_methods.0.reference_value`, {
                required: checkPaymentQuotes,
                valueAsNumber: true
              })}
              className={
                errors.name
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {(() => {
              if (
                errors.payment_methods &&
                errors.payment_methods[0]?.reference_value &&
                checkPaymentQuotes
              )
                return <RequiredField />;
            })()}
          </div>
          <div className={'flex flex-col'}>
            <label>Valor de referencia variante</label>
            <input
              type='text'
              disabled={!checkPaymentQuotes || !hasVariant}
              onKeyDown={(input: any) => {
                const esNumero =
                  (input.keyCode >= 48 && input.keyCode <= 57) || // números de teclado normal
                  (input.keyCode >= 96 && input.keyCode <= 105) ||
                  input.keyCode === 75 ||
                  input.keyCode === 8; // números del teclado numérico

                if (!esNumero) {
                  input.preventDefault(); // detiene la propagación del evento
                }
              }}
              {...register(`payment_methods.0.variant_reference_value`, {
                required: checkPaymentQuotes && hasVariant,
                valueAsNumber: true
              })}
              className={
                errors.name
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {(() => {
              if (
                errors.payment_methods &&
                errors.payment_methods[0]?.variant_reference_value &&
                checkPaymentQuotes
              )
                return <RequiredField />;
            })()}
          </div>
          <div className={'flex flex-col '}>
            <label>Número de cuotas</label>
            <input
              type='text'
              disabled={!checkPaymentQuotes}
              onKeyDown={(input: any) => {
                const esNumero =
                  (input.keyCode >= 48 && input.keyCode <= 57) || // números de teclado normal
                  (input.keyCode >= 96 && input.keyCode <= 105) ||
                  input.keyCode === 75 ||
                  input.keyCode === 8; // números del teclado numérico

                if (!esNumero) {
                  input.preventDefault(); // detiene la propagación del evento
                }
              }}
              {...register(`payment_methods.0.quotes`, {
                required: checkPaymentQuotes,
                valueAsNumber: true,
                onChange: calculateValueQuotes
              })}
              className={
                errors.payment_methods && errors.payment_methods[0]?.quotes
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {(() => {
              if (
                errors.payment_methods &&
                errors.payment_methods[0]?.quotes &&
                checkPaymentQuotes
              )
                return <RequiredField />;
            })()}
          </div>

          <div className={'flex flex-col '}>
            <label>Valor por cuota</label>
            <input
              type='number'
              disabled
              value={valueQuotes}
              className={'py-1 rounded-lg '}
            />
          </div>
          <div className={'flex flex-col '}>
            <label>Valor por cuota variante</label>
            <input
              type='number'
              disabled
              value={variantValueQuotes}
              className={'py-1 rounded-lg '}
            />
          </div>

        </div>
        {/* Pago Anticipado */}
        <div className='my-5'>
          <label className='mx-2'>
            <input
              type='checkbox'
              value={'Anticipado'}
              checked={checkPrepaid}
              onChange={(evt) => setCheckPrepaid(evt.target.checked)}
            />{' '}
            <span className='font-bold text-lg'>Pago Anticipado</span>
          </label>
          <div className={'grid grid-rows-1 grid-flow-col gap-4'}>
            <div className={'flex flex-col'}>
              <label>Valor de referencia</label>
              <input
                type='text'
                disabled={!checkPrepaid}
                onKeyDown={(input: any) => {
                  const esNumero =
                    (input.keyCode >= 48 && input.keyCode <= 57) || // números de teclado normal
                    (input.keyCode >= 96 && input.keyCode <= 105) ||
                    input.keyCode === 75 ||
                    input.keyCode === 8; // números del teclado numérico

                  if (!esNumero) {
                    input.preventDefault(); // detiene la propagación del evento
                  }
                }}
                {...register(`payment_methods.1.reference_value`, {
                  required: checkPrepaid,
                  valueAsNumber: true
                })}
                className={
                  errors.payment_methods &&
                  errors.payment_methods[1]?.reference_value
                    ? 'border-red-500 py-1 rounded-lg'
                    : 'py-1 rounded-lg '
                }
              />
              {(() => {
                if (
                  errors.payment_methods &&
                  errors.payment_methods[1]?.reference_value &&
                  checkPrepaid
                )
                  return <RequiredField />;
              })()}
            </div>
            <div className={'flex flex-col'}>
              <label>Valor de referencia variante</label>
              <input
                type='text'
                disabled={!checkPrepaid || !hasVariant}
                onKeyDown={(input: any) => {
                  const esNumero =
                    (input.keyCode >= 48 && input.keyCode <= 57) || // números de teclado normal
                    (input.keyCode >= 96 && input.keyCode <= 105) ||
                    input.keyCode === 75 ||
                    input.keyCode === 8; // números del teclado numérico

                  if (!esNumero && hasVariant) {
                    input.preventDefault(); // detiene la propagación del evento
                  }
                }}
                {...register(`payment_methods.1.variant_reference_value`, {
                  required: checkPrepaid && hasVariant,
                  valueAsNumber: true
                })}
                className={
                  errors.payment_methods &&
                  errors.payment_methods[1]?.variant_reference_value
                    ? 'border-red-500 py-1 rounded-lg'
                    : 'py-1 rounded-lg '
                }
              />
              {(() => {
                if (
                  errors.payment_methods &&
                  errors.payment_methods[1]?.variant_reference_value &&
                  checkPrepaid && hasVariant
                )
                  return <RequiredField />;
              })()}
            </div>
          </div>
        </div>
        {/* Pago ISA */}
        <label className='mx-2'>
          <input
            type='checkbox'
            value={'ISA'}
            onChange={() => setCheckISA(!checkISA)}
          />{' '}
          <span className='font-bold text-lg'>Pago en ISA</span>
        </label>
        <div className={'grid grid-rows-1 grid-flow-col gap-4'}>
          <div className={'flex flex-col '}>
            <label>Valor ISA</label>
            <input
              type='text'
              disabled={!checkISA}
              onKeyDown={(input: any) => {
                const esNumero =
                  (input.keyCode >= 48 && input.keyCode <= 57) || // números de teclado normal
                  (input.keyCode >= 96 && input.keyCode <= 105) ||
                  input.keyCode === 75 ||
                  input.keyCode === 8; // números del teclado numérico

                if (!esNumero) {
                  input.preventDefault(); // detiene la propagación del evento
                }
              }}
              {...register(`payment_methods.2.isa_value`, {
                required: checkISA,
                valueAsNumber: true
              })}
              className={
                errors.payment_methods && errors.payment_methods[2]?.isa_value
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {(() => {
              if (
                errors.payment_methods &&
                errors.payment_methods[2]?.isa_value &&
                checkISA
              )
                return <RequiredField />;
            })()}
          </div>
        </div>
        {/* Metodos de pago */}
        <p className='py-3 font-bold'> Métodos de Pago</p>
        <div>
          <label className='mx-2' onChange={() => setCheckTransbank(!checkTransbank)}>
            <input
              type='checkbox'
              value={'3'}
              {...register('suppliers', {
                required: true
              })}
              checked={checkTransbank}
            />
            Transbank
          </label>
          <label className='mx-2' onChange={() => setCheckEBanx(!checkEBanx)}>
            <input
              type='checkbox'
              value={'3'}
              {...register('suppliers', {
                required: true
              })}
              checked={checkEBanx}
            />
            EBanx
          </label>
          <div style={{ display: cashType.id === 4 || cashType.id === 5 ? 'inline' : 'none' }}>
            <label className='mx-2' onChange={() => setChangeCheckPaypal(!checkPaypal)}>
              <input
                type='checkbox'
                title='Solo se puede seleccionar este método de pago cuando el tipo de moneda es $USD'
                value={'2'}
                {...register('suppliers', {
                  required: true
                })}
                checked={checkPaypal}
                onChange={() => setChangeCheckPaypal(!checkPaypal)}
              />{' '}
              Paypal
            </label>
          </div>
          <div style={{ display: cashType.id === 1 ? 'inline' : 'none' }}>
            <label className='mx-2' onChange={() => setChangeCheckFlow(!checkFlow)}>
              <input
                type='checkbox'
                value={'1'}
                {...register('suppliers', { required: true })}
                checked={checkFlow}
                onChange={() => setChangeCheckFlow(!checkFlow)}
              />{' '}
              Flow
            </label>
          </div>
          <label className='mx-2' onChange={() => setCheckOtherMethods(!checkOtherMethods)}>
            <input
              type='checkbox'
              value={'4'}
              checked={checkOtherMethods}
              {...register('suppliers', { required: true })}
            />{' '}
            Otro medio de pago
          </label>
          {errors.suppliers && (
            <div>
              <RequiredField />
            </div>
          )}
        </div>
        {/* Botones de acción */}
        <div className={'flex justify-end mt-5'}>
          <button
            className='m-1 px-5 rounded-lg text-white bg-gray-500'
            style={{ border: '3px solid gray' }}
            onClick={closeDialog}
          >
            Cancelar
          </button>
          <button
            type='submit'
            className='m-1 px-5 rounded-lg text-white bg-green-500'
            style={{ border: '3px solid rgb(34 197 94)' }}
          >
            Crear
          </button>
        </div>
      </form>
    </div>
  );
};
