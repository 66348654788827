import { FormDiscountType, FormPaymentType, UpdateFormDiscount, UpdateFormPayment } from '../../../../interfaces';
import { createContext, ReactElement, useReducer } from 'react';

export const initialValue: FormDiscountType = {
  description: null,
  percentage: 0,
  enabled: true,
  sis_discount_motive_id: null
}

type UseDiscountFormContextType = ReturnType<typeof useDiscountFormContext>;

const initContextState: UseDiscountFormContextType = {
  state: initialValue,
  updateForm: () => {}
}

const enum REDUCE_ACTION_TYPE {
  UPDATE_FORM
}

type ReducerAction = {
  type: REDUCE_ACTION_TYPE;
  payload?: FormDiscountType | UpdateFormDiscount;
};

const reducer = (
  state: FormDiscountType,
  action: ReducerAction
): FormDiscountType => {
  switch (action.type) {
    case REDUCE_ACTION_TYPE.UPDATE_FORM:
      state = Object.assign(state, action.payload);
      return { ...state };
    default:
      throw new Error();
  }
};


const useDiscountFormContext = (initialValue: FormDiscountType) => {
  const [state, dispatch] = useReducer(reducer, initialValue);

  const updateForm = (data: UpdateFormDiscount) =>
    dispatch({ type: REDUCE_ACTION_TYPE.UPDATE_FORM, payload: data });

  return { state, updateForm };
}

type ChildrenType = {
  children?: ReactElement | undefined;
};

export const DiscountFormContext =
  createContext<UseDiscountFormContextType>(initContextState);

export const DiscountFormProvider = ({
  children,
  ...initialValue
}: ChildrenType & FormDiscountType) =>{
  return (
    <DiscountFormContext.Provider value={useDiscountFormContext(initialValue)}>
      {children}
    </DiscountFormContext.Provider>
  );
};