import { Link } from 'react-router-dom';
// import { GetFinanceReport } from '../services';
import axios from 'axios';


export const NavLink = () => {

  async function GetFinanceReport(): Promise<void> {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND}/report`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
          },
          responseType: 'blob'
        }
      );

      const blobData = new Blob([response.data]);
      const url = window.URL.createObjectURL(blobData);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'finance_report.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

    } catch (error) {
      console.error(error);
    }
  }

  return (
    <div className="w-full bg-white h-14 drop-shadow-xl flex items-center px-10">
      <Link to={'/servicio_cliente'} className="mx-1 text-blue-400">
        <i className="pi pi-link mr-2"></i>Enlaces
      </Link>
      <Link to={'/tabla_precios'} className="mx-5 text-blue-400">
        <i className="pi pi-table mr-1"></i>Tabla de precios
      </Link>
      <Link to={'/descuentos'} className="mx-5 text-blue-400">
        <i className="pi pi-percentage mr-1"></i>Descuentos
      </Link>
      <Link to={'/generaciones'} className="mx-5 text-blue-400">
        <i className="pi pi-percentage mr-1"></i>Generaciones
      </Link>
      <Link to={'/estadisticas'} className="mx-5 text-blue-400">
        <i className="pi pi-percentage mr-1"></i>Estadísticas
      </Link>
      <button onClick={GetFinanceReport}>
        Descargar Reporte Finanzas
      </button>
    </div>
  );
};
