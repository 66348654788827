import { Dialog } from 'primereact/dialog';
import { FormDataEditGeneration } from './FormDataEditGeneration';

type PropsDialog = {
  id: number;
  actionToast: (action: string) => void;
  reload: () => void;
  open: boolean;
  close: () => void;
}

export const DialogEditGeneration = ({
                                       id,
                                       actionToast,
                                       reload,
                                       open,
                                       close
                                     }
                                       : PropsDialog) => {

  return (
    <Dialog
      visible={open}
      header={'Editar datos generación'}
      draggable={false}
      resizable={false}
      modal
      className='p-fluid'
      onHide={close}>
      <FormDataEditGeneration actionToast={actionToast}
                              id={id}
                              reload={reload}
                              close={close} />

    </Dialog>
  );
};