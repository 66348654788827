type PropsFormUser = {
  currentStep: number;
  setCurrentStep: (value: number) => void;
  dataUser: any;
};

export const WelcomeSection = (props: PropsFormUser) => {

  const nextStep = () => {
    props.setCurrentStep(props.currentStep + 1);
  };

  const regex = /\b\d{1,2} [A-Za-z]+ \d{4}\b/;

  let startDate

  if (props.dataUser) {
    startDate = props.dataUser.generation.description.match(regex);
  }


  return (

    <section className="w-full py-12 md:py-24 lg:py-24 bg-background">
      <div className="container px-4 md:px-6 text-center">
        <div className="max-w-2xl mx-auto space-y-4">
          <h1 className="text-3xl md:text-4xl font-bold tracking-tighter">Bienvenida/o a la Plataforma de pagos de Desafío Latam</h1>
          <p className="text-muted-foreground md:text-xl">
            Nos alegra que estés aquí. En Desafío Latam, tu seguridad y confianza son nuestra prioridad, por lo que hemos creado una nueva plataforma para que puedas gestionar tus pagos de manera rápida y segura.
          </p>
          <p>Ya estás a un paso de obtener tu programa. A continuación te pediremos algunos datos para que puedas empezar a aprender con nostros. </p>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center gap-4 my-4">
        <div className="bg-card p-4 rounded-md text-left border border-black/10 flex justify-between items-end">
          <div className="flex-grow">
            <h3 className="text-lg font-semibold">Detalles</h3>
            <div className="mt-2">
              <p className="text-muted-foreground">
                Tu programa: {props.dataUser ? props.dataUser.career.friendly_name : ''}
              </p>
              <p className="text-muted-foreground">
                Fecha de inicio: {startDate}
              </p>
            </div>
          </div>
          <div className="ml-8">
            <button
              className="m-1 btn"
              type="button"
              onClick={() => nextStep()}
            >
              Empezar
            </button>
          </div>
        </div>
      </div>
      <div className='flex justify-center items-center bg-gray-100'>
        <img
          alt='Background'
          className=''
          src={require('../../../assets/images/pago_finalizado.png')}
          width={700}
        />
      </div>
    </section>

    // <section className="w-full py-12 md:py-24 lg:py-24 bg-background">
    //   <div className="container px-4 md:px-6 text-center">
    //     <div className="max-w-2xl mx-auto space-y-4">
    //       <h1 className="text-3xl md:text-4xl font-bold tracking-tighter">Bienvenida/o a la Plataforma de pagos de Desafío Latam</h1>
    //       <p className="text-muted-foreground md:text-xl">
    //         Nos alegra que estés aquí. En Desafío Latam, tu seguridad y confianza son nuestra prioridad, por lo que hemos creado una nueva plataforma para que puedas gestionar tus pagos de manera rápida y segura.
    //       </p>
    //       <p>Ya estás a un paso de obtener tu programa. A continuación te pediremos algunos datos para que puedas empezar a aprender con nostros. </p>
    //     </div>
    //     <div>
    //       {/* Tu programa: {props.dataUser.career.friendly_name} */}
    //       Inicio de clases: {startDate }
    //     </div>
    //   </div>


    // </section>
  );
};
