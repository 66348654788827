import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormDiscountType, FormPaymentType } from '../../../../interfaces';
import { useDialogCreateLinkHook } from '../../section-payment/context/TableContext';
import { initialValue } from '../context/DiscountFormContext';
import axios from 'axios';
import { AxiosError } from 'axios/index';
import { RequiredField } from '../../../../components';

type PropsFormDataDiscount = {
  actionToast: (action: string) => void;
  isLoad: (value: boolean) => void;
  reload: () => void;
}
export const FormDataDiscount = ({
                                   actionToast,
                                   isLoad,
                                   reload
                                 }: PropsFormDataDiscount) => {

  const { closeDialog } = useDialogCreateLinkHook();
  const {
    handleSubmit,
    register,
    formState: { errors }
  } = useForm<FormDiscountType>({
    defaultValues: { ...initialValue }
  });

  const onSubmit: SubmitHandler<FormDiscountType> = (data) => {

    axios.post(`${process.env.REACT_APP_API_BACKEND}/discounts`, data, {
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
      }
    }).then((response) => {
      console.log('Response Discount =>', response.data);
      actionToast('success');
      reload();
    })
      .catch((error: AxiosError) => {
        console.log('Error Discount =>', error);
      })
      .finally(() => {
        isLoad(false);
        closeDialog();
      });
  };

  const RequiredField = () => {
    return <span className='text-red-500 text-[12px]'>Campo requerido</span>;
  };

  return (<div className='w-full'>
      <form onSubmit={handleSubmit(onSubmit)} className='relative'>
        <hr className='py-3' />


        <div className='grid grid-rows-1 grid-flow-col gap-4'>
          <div className='flex flex-col'>
            <label>Descripción</label>
            <input
              type='text'
              {...register('description', { required: true })}
              className={
                errors.description
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {errors.description && <RequiredField />}
          </div>
          <div className='flex flex-col'>
            <label>Porcentage</label>
            <input
              type='float'
              {...register('percentage', {
                required: true,
                valueAsNumber: true,
                min: 0,
                max: 100
              })}
              className={
                errors.percentage
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {errors.percentage && <RequiredField />}
          </div>
          <div className='flex flex-col'>
            <label>Id Motivo descuento Sis</label>
            <input
              type='number'
              {...register('sis_discount_motive_id', {
                required: true,
                valueAsNumber: true
              })}
              className={
                errors.sis_discount_motive_id
                  ? 'border-red-500 py-1 rounded-lg'
                  : 'py-1 rounded-lg '
              }
            />
            {errors.percentage && <RequiredField />}
          </div>
          <div className='flex flex-col'>
            <label className='mx-2'>
              <input
                type='checkbox'
                value={'3'}
                {...register('enabled', {
                  required: true
                })}
              />
              Habilitado
            </label>
          </div>
        </div>

        <div className={'flex justify-end mt-5'}>
          <button
            className='m-1 px-5 rounded-lg text-white bg-gray-500'
            style={{ border: '3px solid gray' }}
            onClick={closeDialog}
          >
            Cancelar
          </button>
          <button
            type='submit'
            className='m-1 px-5 rounded-lg text-white bg-green-500'
            style={{ border: '3px solid rgb(34 197 94)' }}
          >
            Crear
          </button>
        </div>
      </form>
    </div>
  );
};