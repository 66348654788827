import { Dialog } from 'primereact/dialog';
import { DiscountFormProvider, initialValue } from '../context/DiscountFormContext';
import { FormDataEditDiscount } from './FormDataEditDiscount';

type PropsDialog = {
  id: number;
  actionToast: (action: string) => void;
  reload: () => void;
  open: boolean;
  close: () => void;
};

export const DialogEditDiscount = ({
                                     id,
                                     actionToast,
                                     reload,
                                     open,
                                     close
                                   }: PropsDialog) => {
  return (<Dialog
    visible={open}
    header={'Editar Item'}
    draggable={false}
    resizable={false}
    modal
    className='p-fluid'
    onHide={close}>
    <DiscountFormProvider {...initialValue}>
      <FormDataEditDiscount actionToast={actionToast}
                            id={id}
                            reload={reload}
                            close={close} />
    </DiscountFormProvider>
  </Dialog>);
};