import { Navbar } from '../../../components';
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CompletadosVsCancelados } from './components/CompletadosVsCancelados';


export const SectionEstadisticas = () => {
  const [registerData, setRegisterData] = useState([]);
  const [programsLabels, setProgramLabels] = useState([]);


  const fetchData = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_API_BACKEND}/registers`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
          }
        }
      )
      .then((response) => {
        setRegisterData(response.data);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    let values = registerData?.map((e) => {
      return e['variant_application'] ? e['career']['variant_friendly_name'] : e['career']['friendly_name'] ;
    })
    setProgramLabels(values.filter((value, index, array) => array.indexOf(value) === index));

    }, [registerData]);

  var config = { responsive: true };


  return (

    <>
      <Navbar />
      <div className='pt-48 px-10 w-full h-screen bg-[#F9F9F9]'>
        <CompletadosVsCancelados data={registerData} programsLabels={programsLabels} />
      </div>
    </>
  );

};