import React, { useEffect, useState } from 'react';
import { ClassesHistogram } from './ClassesHistogram';
import { LoadingComponent } from '../../../../components';

type CompletadosVsCanceladosProps = {
  programsLabels: string[];
  data: any[];
}
export const CompletadosVsCancelados = (props: CompletadosVsCanceladosProps) => {
  const [loading, setLoading] = useState(true);
  const [programsQuantityValues, setProgramsQuantityValues] = useState(Array<number>);
  const [programsQuantityCanceladosValues, setProgramsQuantityCanceladosValues] = useState(Array<number>);
  const [programsQuantityCompletadosValues, setProgramsQuantityCompletadosValues] = useState(Array<number>);
  const [programsQuantityPaymentAgreedValues, setProgramsQuantityPaymentAgreedValues] = useState(Array<number>);

  useEffect(() => {
    let todes = props.data?.map((e) => {
      return e['variant_application'] ? e['career']['variant_friendly_name'] : e['career']['friendly_name'];
    });

    let canceled: any[] = props.data?.filter(r => r['status'] === 'Cancelado').map((e) => {
      return e['variant_application'] ? e['career']['variant_friendly_name'] : e['career']['friendly_name'];
    });
    let completed: any[] = props.data?.filter(r => r['status'] === 'Completado').map((e) => {
      return e['variant_application'] ? e['career']['variant_friendly_name'] : e['career']['friendly_name'];
    });
    let payment_agreed: any[] = props.data?.filter(r => r['status'] === 'Pago Convenido').map((e) => {
      return e['variant_application'] ? e['career']['variant_friendly_name'] : e['career']['friendly_name'];
    });
    setProgramsQuantityValues(props.programsLabels.map(label => {
      return todes?.filter(p => p === label).length;
    }));

    setProgramsQuantityCanceladosValues(props.programsLabels.map(label => {
      return canceled?.filter(p => p === label).length;
    }));

    setProgramsQuantityCompletadosValues(props.programsLabels.map(label => {
      return completed?.filter(p => p === label).length;
    }));

    setProgramsQuantityPaymentAgreedValues(props.programsLabels.map(label => {
      return payment_agreed?.filter(p => p === label).length;
    }));
    console.log(programsQuantityValues);
  }, [props.programsLabels, props.data]);

  return <>
    <ClassesHistogram title='Distribuición de Programas' x={props.programsLabels}
                      labels={[
                        'Enviados',
                        'Cancelados',
                        'Completados',
                        'Pagos Convenidos'
                      ]}
                      traces={[
                        programsQuantityValues,
                        programsQuantityCanceladosValues,
                        programsQuantityCompletadosValues,
                        programsQuantityPaymentAgreedValues
                      ]}

                      colors={['#123456', '#654321']} />
  </>;
};