import React, { useEffect, useState } from 'react';

type PropsPanel = {
  dataUser: any;
};

interface ConfirmationData {
  authorization_code: string;
  payment_type: string;
  vci: string;
}

export const FormaPagoComponent = (props: PropsPanel) => {

  const [isTransbank, setIsTransbank] = useState(false);
  const [confirmationData, setConfirtmationData] = useState<ConfirmationData>();

  function calculateStatus(status: any): string {
    switch (status) {
      case 1:
        return 'Pendiente';
      case 2:
        return 'Pagado';
      case 3:
        return 'Rechazado';
      default:
        return '';
    }
  }

  useEffect(() => {
    if (props.dataUser.purchase?.supplier === 'Transbank') {
      setIsTransbank(true);
      setConfirtmationData(JSON.parse(props.dataUser.purchase?.purchase_metadata));
    }

  }, [props]);
  return (
    <>
      <div className='grid gap-4 grid-cols-8'>
        <div className='col-span-2 flex flex-col'>
          <label>Id del pago</label>
          <input type='text' value={props.dataUser.purchase?.id ?? ''} disabled />
        </div>
        <div className='col-span-2 flex flex-col'>
          <label>Forma de pago</label>
          <input type='text' value={props.dataUser.purchase?.payment_method?.description ?? ''} disabled />
        </div>
        <div className='col-span-2 flex flex-col'>
          <label>Total a pagar</label>
          <input type='text' value={props.dataUser.purchase?.total ?? ''} disabled />
        </div>
      </div>
      <div className='grid gap-4 grid-cols-8 '>
        <div className='col-span-2 flex flex-col'>
          <label>Proveedor:</label>
          <input type='text' value={props.dataUser.purchase?.supplier ?? ''} disabled />
        </div>
        <div className='col-span-2 flex flex-col'>
          <label>Paga matrícula:</label>
          <input type='text' value={props.dataUser.tuition_exempted ? 'No' : 'Si'} disabled />
        </div>
        <div className='col-span-2 flex flex-col'>
          <label>Estado de pago</label>
          <input type='text' value={calculateStatus(props.dataUser.purchase?.status ?? '')} disabled />
        </div>
      </div>

      {isTransbank && (
        <div className='gap-4 pt-3'>
          <label className='grid gap-4 text-2xl'>Confirmation data</label>
          <div className='grid gap-4 grid-cols-8 '>
            <div className='col-span-2 flex flex-col'>
              <label>Auhtorization code</label>
              <input type='text' defaultValue={confirmationData ? confirmationData.authorization_code : ''} disabled />
            </div>
            <div className='col-span-2 flex flex-col'>
              <label>Payment type</label>
              <input type='text' defaultValue={confirmationData ? confirmationData.payment_type : ''} disabled />
            </div>
            <div className='col-span-2 flex flex-col'>
              <label>vci</label>
              <input type='text' defaultValue={confirmationData ? confirmationData.vci : ''} disabled />
            </div>
          </div>
        </div>

      )}

    </>
  );
};
