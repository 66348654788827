import { Navbar } from '../../../components';
import React from 'react';
import { initialState, TableProvider } from '../section-payment/context/TableContext';
import { TableGenerations } from './components/TableGenerations';


export const SectionGenerations = () => {
  return (

      <>
        <Navbar />
        <div className="pt-48 px-10 w-full h-screen bg-[#F9F9F9]">
        
            <TableGenerations />
    
        </div>
      </>
    );

}