import { Navbar } from '../../../components';
import React from 'react';
import { initialState, TableProvider } from '../section-payment/context/TableContext';
import { TableDiscounts } from './components/TableDiscounts';
import { DiscountFormProvider, initialValue } from './context/DiscountFormContext';


export const SectionDiscounts = () => {
  return (
    <DiscountFormProvider {...initialValue}>
      <>
        <Navbar />
        <div className="pt-48 px-10 w-full h-screen bg-[#F9F9F9]">
          <TableProvider {...initialState}>
            <TableDiscounts />
          </TableProvider>
        </div>
      </>
    </DiscountFormProvider>);
}