import React from 'react';
import { Currency, DefaultPrice } from '../../../../interfaces';
import { CurrencyPriceTableSelector } from './CurrencyPriceTableSelector';


type CurrenciesPricesTableSelectorProps = {
  prices: any[];
  currencies: Currency[];
  defaultPrices: DefaultPrice[];
  setDefaultPrices: (value: any) => void;
  generation_id: number;
}
export const CurrenciesPricesTableSelector = (props: CurrenciesPricesTableSelectorProps) => {
  const handleSelectDefaultPricesTable = (currency_id: number, price_id: number) => {
    console.log(`handleSelectDefaultPricesTable ${currency_id} ${price_id}`);
    let defaultPrice = props.defaultPrices.find(p => p.currency_id == currency_id);
    if (defaultPrice) {
      defaultPrice.price_id = price_id;
    } else {
      props.defaultPrices.push({ currency_id: currency_id, generation_id: props.generation_id, price_id: price_id })
    }
    console.log(props.defaultPrices);
    props.setDefaultPrices(props.defaultPrices);
  };

  return (<>
    <div className='flex flex-col'>
      {props.currencies.map((currency: Currency) => (
        <CurrencyPriceTableSelector
          currency={currency}
          currentPriceValue={props.defaultPrices.find((dp) => (dp.currency_id === currency.id))?.price_id}
          priceOptions={props.prices} updatePriceHandler={handleSelectDefaultPricesTable} />
      ))}
    </div>
  </>);
};