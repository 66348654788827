import axios from 'axios';
import { useEffect, useState } from 'react';
import { DiscountType } from '../interfaces';

type GetDiscountsProps = {
  setListDiscounts: any;
  enabled: any;
}

export const GetDiscountsTable = (props: GetDiscountsProps) => {
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try{
      const response = await axios.get(
        `${process.env.REACT_APP_API_BACKEND}/discounts?enabled=${props.enabled ? 1: 0}`,
        {
          headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token_hhrr_latam')}`
          }
        }
      );
      props.setListDiscounts(response.data);
    } catch (error) {
      console.log('Error =>', error);
      setError(true);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    fetchData();
  }, []);

  const reload = () => {
    fetchData();
  }

  return { error, loading , reload};
}