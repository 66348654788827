import { useState } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Currency } from '../../../../interfaces';

type CurrencyPriceTableSelectorProps = {
  currency: Currency;
  currentPriceValue: number | undefined;
  priceOptions: any[];
  updatePriceHandler: (currency_id: number, price_id: number) => void
}
export const CurrencyPriceTableSelector = (props: CurrencyPriceTableSelectorProps) => {

  const [currentValue, setCurrentValue] = useState(props.currentPriceValue);

  const handleChangePriceTable = (currency_id: number, price_id: number) => {
    console.log(`${currency_id} ${price_id}`);
    setCurrentValue(price_id);
    props.updatePriceHandler(currency_id, price_id);
  }

  return <>
    <div className='w-full '>
      <label className='my-2'>{props.currency.code}</label>
      <Dropdown
        className='w-full md:w-14rem'
        value={currentValue}
        onChange={(e) => handleChangePriceTable(props.currency.id, e.value)}
        options={props.priceOptions
          .filter((price: any) => (price.currency_id === props.currency.id))
          .map((price: any) => (
            { 'value': price.id, 'label': `${price.name}`}
          ))}
      >
      </Dropdown>
    </div>
  </>
}

