import Plot from 'react-plotly.js';
import { Data } from 'plotly.js';


type ClassesHistogramProps = {
  title: string;
  x: Array<string>;
  traces: Array<Array<number>>;
  colors: Array<string>;
  labels: Array<string>;
}
export const ClassesHistogram = (props: ClassesHistogramProps) => {

  const data: Data[] = props.traces.map((el, index) => {
    return {
      x: props.x,
      y: el,
      type: 'bar',
      name: props.labels[index],
      marker: {color: props.colors[index]}
    }
  });
  return (
    <>
      <Plot
        data={data}
        layout={ {title: props.title, autosize: true } }
      />
    </>);
}