import React, { useEffect } from 'react';
import { Navbar } from '../../components';
import { useNavigate } from 'react-router-dom';
import axios, { AxiosError } from 'axios';
import { ProgressSpinner } from 'primereact/progressspinner';


export const FinishPaymentPage = () => {
  const navigate = useNavigate();
  
  useEffect(() => {
		getStatus();
  }, []);

	const getStatus = async (): Promise<void> => {
					navigate(
						`/formulario/${localStorage.getItem(
							'type_form'
						)}?register=${localStorage.getItem(
							'register_id'
						)}&token=${localStorage.getItem('token_user_latam')}`
					);
	}

    return <div className='relative h-screen w-screen flex justify-center items-center'><ProgressSpinner /></div>;
};
